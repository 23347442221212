@import url(https://fonts.googleapis.com/css2?family=Barlow:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Playfair+Display:wght@600&display=swap);
/*@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@600&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@100&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+Display&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@300&display=swap');
*/

*{
  margin: 0;
  padding: 0;
  margin-right: 0;
}

body::-webkit-scrollbar {
  width: 4px;              /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  background: black;    /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background-color: blue;    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
  border: 3px solid orange;  /* creates padding around scroll thumb */
}
